import { ApiResponse } from '../models/ApiResponse';
import { ClientForm } from '../models/ClientForm';
import BaseService from './BaseService';
import {
  DocumentNumberValidateResponse,
  DocumentReplaceRequest,
  DocumentRequest,
  DocumentResponse,
  UncontrolledCopyLog,
  UncontrolledCopyLogRequest,
} from '../models/Document';
import { UncontrolledCopyRequest } from '../models/Distribution';

export default class DocumentService extends BaseService {
  public static create(payload: DocumentRequest): Promise<ApiResponse<ClientForm>> {
    return this.post('/v1/documents', payload);
  }
  public static validateNumber(clientModuleId: string, docNumber: number): Promise<ApiResponse<DocumentNumberValidateResponse>> {
    return this.post('/v1/documents/validate-number', { clientModuleId: clientModuleId, number: docNumber });
  }
  public static replace(document: DocumentReplaceRequest): Promise<ApiResponse<DocumentResponse>> {
    return this.put('/v1/documents/replace', document);
  }
  public static sendUncontrolledCopy(request: UncontrolledCopyRequest): Promise<ApiResponse<void>> {
    return this.post(`/v1/documents/send-uncontrolled-copy`, request);
  }

  public static getUncontrolledCopyLogs(filter: UncontrolledCopyLogRequest): Promise<ApiResponse<[]>> {
    return this.get(`/v1/documents/uncontrolled-copy-logs`, { params: { filter } });
  }
}
