import { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import useDebounce from '../../../hooks/useDebounce';
import { ApiResponse } from '../../../models/ApiResponse';
import { FormType } from '../../../models/FormTypes';
import ClientFormService from '../../../services/ClientFormService';
import { nextTick } from '../../../utils/ReactUtils';
import Button, { ButtonType } from '../../shared/form-control/Button';
import { Item } from '../../shared/form-control/DropdownDefaultComponents';
import DropdownSelect, { DropdownSize } from '../../shared/form-control/DropdownSelect';
import { InputStyle } from '../../shared/form-control/Input';
import { SearchInput } from '../../shared/form-control/SearchInput';
import StandardModal from '../../shared/modal/variants/StandardModal';
import SkeletonLoader from '../../shared/skeleton-loader/SkeletonLoader';
import UploadFile from '../UploadFile';
import ReferenceDocumentsList, { SelectableDocumentListItem } from '../reference-document/ReferenceDocumentsList';
import { useRecoilValue } from 'recoil';
import { currentClientAtom } from '../../../recoil/atoms/Clients';
import { useCreateDocumentWizard } from '../../../contexts/CreateDocumentContext';
import LanguageUtils from '../../../utils/LanguageUtils';
import FileService from '../../../services/FileService';
import { FileInfo } from '../../shared/file-upload/FileInfo';
import { DocumentReferenceAnswer } from '../../form/action-types/document-reference/DocumentReferenceAction';
import { DocumentListItem } from '../../../models/Document';
import { useParams } from 'react-router';

const ReferenceStep = () => {
  const { classes, selectedDocuments, disabledDocments, newDocument, setNewDocument, nextStep, onReference, cancel } = useCreateDocumentWizard();
  const currentClient = useRecoilValue(currentClientAtom);
  const { t } = useTranslation(['activity-type', 'common', 'documents', 'module']);
  const all = useMemo(() => ({ id: '', text: t('module:sections-all'), value: '' }), [t]);

  const { formId: currentFormId } = useParams<{ formId: string }>();

  const [isLoadingDocuments, setIsLoadingDocuments] = useState(false);
  const [selectedClassOption, setSelectedClassOption] = useState<Item>(all);
  const [searchValue, setSearchValue] = useState('');
  const debouncedSearchTerm = useDebounce(searchValue, 500);
  const [sortBy, setSortBy] = useState('+prefix,+number,+subtitle');
  const pageContentRef = useRef<HTMLDivElement>(null);
  const [currentPage, setCurrentPage] = useState(1);
  const [documents, setDocuments] = useState<ApiResponse<DocumentListItem[]>>();
  const [selectedDocumentsInternal, setSelectedDocumentsInternal] = useState<DocumentReferenceAnswer[]>([]);

  useEffect(() => {
    setSelectedDocumentsInternal(selectedDocuments || []);
  }, [selectedDocuments]);

  const disabledIds = useMemo(() => new Set(disabledDocments?.map((x) => x.id) || []), [disabledDocments]);

  const classOptions = useMemo(() => {
    return [
      all,
      ...classes
        .sort((a, b) => a.templateModule.sortOrder - b.templateModule.sortOrder)
        .map((item) => ({
          id: item.clientModuleId,
          text: LanguageUtils.getTranslation('name', item.templateModule.translations || {}),
          value: item.clientModuleId,
        })),
    ];
  }, [all, classes]);

  const documentsFilter = useMemo(() => {
    return {
      clientModuleId: selectedClassOption?.id,
      title: debouncedSearchTerm,
      types: [FormType.Document],
      pageSize: 10,
      sortBy: sortBy,
    };
  }, [selectedClassOption?.id, debouncedSearchTerm, sortBy]);

  useEffect(
    function resetFilters() {
      setCurrentPage(1);
      setDocuments(undefined);
      // Reset when any of these filters change
    },
    [documentsFilter?.title, documentsFilter?.sortBy, selectedDocuments],
  );

  const filterDocuments = useCallback(() => {
    if (!currentClient) return;

    const scrollTop = pageContentRef.current?.scrollTop || 0;
    setIsLoadingDocuments(true);

    ClientFormService.getFormsPaged(currentClient.id, {
      ...documentsFilter,
      pageNumber: currentPage,
      isArchived: false,
    }).then((res) => {
      setDocuments((prev) => {
        if (prev?.data && currentPage > 1) {
          return {
            ...res,
            data: [...prev.data, ...res.data],
          } as ApiResponse<DocumentListItem[]>;
        }

        return { ...res } as ApiResponse<DocumentListItem[]>;
      });

      setIsLoadingDocuments(false);

      nextTick(() => {
        pageContentRef.current?.scrollTo({ top: scrollTop });
      });
    });
  }, [currentClient, currentPage, documentsFilter]);

  const selectableDocuments = useMemo(() => {
    // Create a set for faster lookup
    const selectedItemsSet = new Set(selectedDocumentsInternal?.map((x) => x.id));
    return {
      ...documents,
      data: documents?.data.map((item) => ({
        ...item,
        selected: selectedItemsSet.has(item.id),
        disabled: disabledIds.has(item.id) || currentFormId === item.id,
      })),
    } as ApiResponse<SelectableDocumentListItem[]> | null;
  }, [currentFormId, disabledIds, documents, selectedDocumentsInternal]);

  useEffect(() => {
    filterDocuments();
  }, [filterDocuments]);

  const onSortBy = (expression: string) => {
    setDocuments(undefined);
    setCurrentPage(1);
    setSortBy(expression);
  };

  const doneClick = (): void => {
    onReference && onReference(selectedDocumentsInternal);
    setSearchValue('');
    cancel && cancel();
  };

  const onSelectedClassChange = useCallback((value: Item) => {
    setDocuments(undefined);
    setCurrentPage(1);
    setSelectedClassOption(value);
  }, []);

  const onDocumentSelect = (id: string, selected: boolean) => {
    setSelectedDocumentsInternal((prev) => {
      if (selected) {
        return [...new Set([...prev, { id }])];
      } else {
        return prev.filter((x) => x.id !== id);
      }
    });
  };

  const [fileUploadProgress, setFileUploadProgress] = useState(newDocument.file ? 1 : 0);

  const deleteFile = useCallback(() => {
    setFileUploadProgress(0);
    if (newDocument.fileId) {
      return FileService.deleteFile(newDocument.fileId).then(() => {
        setNewDocument && setNewDocument((prev) => ({ ...prev, fileId: undefined, file: undefined, subtitle: '' }));
      });
    }
    return Promise.resolve();
  }, [newDocument.fileId, setNewDocument]);

  const onUpload = useCallback(
    async (fi: FileInfo[]) => {
      if (newDocument.file) {
        await deleteFile();
      }

      let fileName = fi[0]!.name;
      fileName = fileName.slice(0, fileName.lastIndexOf('.'));
      FileService.uploadFile(fi[0].file, setFileUploadProgress).then((res) => {
        setNewDocument && setNewDocument((prev) => ({ ...prev, fileId: res.data[0].id, file: fi[0], subtitle: fileName }));
        nextStep && nextStep('detailStep');
      });
    },
    [deleteFile, newDocument.file, nextStep, setNewDocument],
  );

  // Delete the file on render incase the user navigate back
  useEffect(() => {
    deleteFile();
  }, [deleteFile]);

  return (
    <StandardModal
      title={t('document-reference-modal.title')}
      subTitle={t('document-reference-modal.subTitle')}
      confirmButtonTitle={t('activity-type:document-reference-modal.buttons.done')}
      onConfirmClick={doneClick}
      confirmDisabled={fileUploadProgress > 0 && fileUploadProgress < 100 && !newDocument.file}
      onCancelClick={cancel && cancel}
      cancelButtonTitle={t('activity-type:document-reference-modal.buttons.cancel')}
    >
      <div className="flex h-[60vh] flex-grow gap-2 overflow-hidden">
        <div className="border-gray-6 relative w-3/5 border-r pr-2">
          <div className="text-dpm-16 mb-4 font-medium">{t('activity-type:document-reference-modal.select-existing')}</div>
          <div className="flex gap-2">
            <div className="flex-grow">
              <DropdownSelect options={classOptions} value={selectedClassOption} onChange={onSelectedClassChange} size={DropdownSize.S} />
            </div>
            <div className="w-80">
              <SearchInput
                value={searchValue}
                onChange={(e) => setSearchValue(e.target.value)}
                placeholder={t('common:list.filter.search')}
                style={InputStyle.MINIMAL}
                data-cy="doc-search"
              />
            </div>
          </div>
          <div className="mt-4 h-[50vh] flex-grow overflow-auto pr-2" ref={pageContentRef}>
            <SkeletonLoader type="listBlockRow" rows={6} size="small" ready={!!documents} className="justify-start">
              {selectableDocuments && (
                <ReferenceDocumentsList
                  documentsPaged={selectableDocuments}
                  onDocumentSelect={onDocumentSelect}
                  sortBy={sortBy}
                  isLoading={isLoadingDocuments}
                  onLoadMore={setCurrentPage}
                  onSort={onSortBy}
                />
              )}
            </SkeletonLoader>
          </div>
        </div>
        <div className="w-2/5">
          <div className="flex flex-col items-center">
            <div className="text-dpm-16 mb-3 w-full text-left font-medium">{t('activity-type:document-reference-modal.upload-new')}</div>
            <div className="w-full flex-grow">
              <UploadFile file={newDocument.file} deleteFile={deleteFile} onUpload={onUpload} fileUploadProgress={fileUploadProgress} />
            </div>
            <div className="h-48">
              <div className="mb-8 mt-12 flex w-full items-center justify-center">
                <div className="bg-gray-4 h-px w-1/4"></div>
                <div className="mx-2 text-gray-600">{t('activity-type:document-reference-modal.or')}</div>
                <div className="bg-gray-4 h-px w-1/4"></div>
              </div>
              <Button type={ButtonType.SECONDARY} onClick={() => nextStep && nextStep('fromTemplate')}>
                {t('activity-type:document-reference-modal.buttons.from-template')}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </StandardModal>
  );
};

export default ReferenceStep;
