import { FC, useCallback, useMemo, useState } from 'react';
import Button, { ButtonSize, ButtonType } from './form-control/Button';
import { Trans, useTranslation } from 'react-i18next';
import DateUtils from '../../utils/DateUtils';
import CalendarIcon from './icon/CalendarIcon';
import { ModalContext } from '../../contexts/ModalContext';
import StandardModal from './modal/variants/StandardModal';
import RadioButton from './form-control/RadioButton';
import { DatePicker, DatePickerType } from './form-control/DatePicker';
import Tooltip from './Tooltip';
import InfoIcon from './icon/InfoIcon';
import TimeAgo from './TimeAgo';

type Props = {
  value: Date | null;
  onChange: (value: Date | null) => void;
  disabled?: boolean;
  errorState?: boolean;
};

const EffectiveDatePicker: FC<Props> = (props) => {
  const { value: externalValue, onChange, disabled, errorState } = props;

  const [modalOpen, setModalOpen] = useState(false);
  const [modalValue, setModalValue] = useState<Date | null>(externalValue);

  const { t } = useTranslation('form');

  const text = useMemo(() => {
    if (!externalValue) return t('effective-date.after-completion');

    return DateUtils.formatDate(externalValue);
  }, [t, externalValue]);

  const onModalClose = useCallback(() => {
    setModalOpen(false);
    setModalValue(externalValue);
  }, [externalValue]);

  const errorText = useMemo(() => {
    if (!errorState) return '';

    return <TimeAgo date={externalValue} disableTime />;
  }, [errorState, externalValue]);

  return (
    <div>
      <Tooltip text={errorText}>
        {(tooltip) => (
          <span {...tooltip}>
            <Button
              type={ButtonType.TERTIARY}
              size={ButtonSize.S}
              disabled={disabled}
              onClick={() => setModalOpen(true)}
              className={errorState ? 'text-semantic-2' : ''}
            >
              <Button.Slot name="Icon">
                <CalendarIcon className="h-4 w-4" />
              </Button.Slot>

              {text}
            </Button>
          </span>
        )}
      </Tooltip>

      <ModalContext.Provider value={{ open: modalOpen, onClose: onModalClose, modalWidth: 'w-2/5' }}>
        <StandardModal
          title={t('effective-date.title')}
          subTitle={t('effective-date.subtitle')}
          confirmButtonTitle={t('effective-date.buttons.confirm')}
          onConfirmClick={() => {
            onChange(modalValue);
            setModalOpen(false);
          }}
          onCancelClick={onModalClose}
        >
          <EffectiveDateModalContent value={modalValue} setValue={setModalValue} />
        </StandardModal>
      </ModalContext.Provider>
    </div>
  );
};

export default EffectiveDatePicker;

type ModalProps = {
  value: Date | null;
  setValue: (value: Date | null) => void;
  heading?: boolean;
};

export const EffectiveDateModalContent: FC<ModalProps> = (props) => {
  const { value, setValue, heading } = props;

  const { t } = useTranslation('form');

  return (
    <div>
      {heading && (
        <span>
          {t('effective-date.effective-date')}{' '}
          <Tooltip text={<Trans t={t} i18nKey={'effective-date.explanation'} components={{ p: <p className="my-1" /> }} />}>
            {(tooltip) => (
              <span {...tooltip}>
                <InfoIcon className="h-4 w-4" />
              </span>
            )}
          </Tooltip>
        </span>
      )}

      <RadioButton value={value === null} label={t('effective-date.options.equals')} onChange={() => setValue(null)} />

      <div className="flex items-center justify-between">
        <RadioButton value={value !== null} label={t('effective-date.options.fixed-date')} onChange={() => setValue(DateUtils.now)} />
        {value !== null && (
          <DatePicker
            date={value}
            type={DatePickerType.BUTTON}
            buttonContent={DateUtils.formatDate(value)}
            buttonType={ButtonType.TERTIARY}
            buttonSize={ButtonSize.S}
            buttonIcon={<CalendarIcon className="h-4 w-4" />}
            buttonClassName="text-primary-1"
            notBefore={DateUtils.now}
            onChange={(value) => setValue(value)}
          />
        )}
      </div>
    </div>
  );
};
