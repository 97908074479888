import { FC } from 'react';
import { FileUtils } from '../../utils/FileUtils';
import AttachmentIcon from '../shared/icon/AttachmentIcon';
import XIcon from '../shared/icon/XIcon';
import { FileInfo } from '../shared/file-upload/FileInfo';
import Tooltip from '../shared/Tooltip';

type FileProps = {
  file: FileInfo;
  onRemove: () => void;
};

const File: FC<FileProps> = (props) => {
  const { file, onRemove } = props;
  return (
    <div className="bg-gray-6 my-4 flex w-full items-center justify-between rounded-sm p-2">
      <div className="flex gap-2 truncate">
        <div className="h-full rounded-sm bg-white p-3">
          <AttachmentIcon className="text-primary-2 h-6 w-6" />
        </div>
        <div className="truncate">
          <Tooltip text={file.name} truncatedTextMode>
            {(tooltip) => (
              <div {...tooltip} className="truncate text-black">
                {file.name}
              </div>
            )}
          </Tooltip>

          <div className="text-gray-2">{FileUtils.formatSize(file.size || 0)}</div>
        </div>
      </div>
      <div>
        <XIcon className="h-5 w-5" onClick={onRemove} />
      </div>
    </div>
  );
};

export default File;
