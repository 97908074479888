import { Trans, useTranslation } from 'react-i18next';
import ProgressBar from '../shared/ProgressBar';
import UploadArea from '../shared/file-upload/UploadArea';
import AttachmentIcon from '../shared/icon/AttachmentIcon';
import CloudUploadIcon from '../shared/icon/CloudUploadIcon';
import TrashIcon from '../shared/icon/TrashIcon';
import { FC } from 'react';
import { FileInfo } from '../shared/file-upload/FileInfo';

type UploadFileProps = {
  file?: FileInfo;
  fileUploadProgress: number;
  onUpload: (file: FileInfo[]) => void;
  deleteFile: () => void;
};

const UploadFile: FC<UploadFileProps> = (props) => {
  const { fileUploadProgress, onUpload, deleteFile, file } = props;
  const { t } = useTranslation(['documents']);

  return (
    <>
      <UploadArea
        onUpload={onUpload}
        className={`${fileUploadProgress > 0 ? '' : 'h-full'}`}
        acceptMimes="application/vnd.openxmlformats-officedocument.wordprocessingml.document, application/pdf"
      >
        {(triggerUploadDialogue) => (
          <div className="py-6 text-center text-black">
            <CloudUploadIcon className={`bg-primary-1 text-primary-1 my-4 h-12 w-12 rounded-full bg-opacity-10 p-3`} />
            <div>
              <Trans
                t={t}
                i18nKey="create-doc-wizard.file-upload.description"
                components={{
                  Link: <span className="cursor-pointer font-medium underline" onClick={triggerUploadDialogue} />,
                }}
              />
            </div>
            <div className="text-gray-2 my-3">{t('create-doc-wizard.file-upload.formats')}</div>
          </div>
        )}
      </UploadArea>

      <div className="mt-4 flex flex-col gap-1">
        {file && (
          <div className="flex items-center justify-between gap-4">
            <div className="flex items-center gap-4 truncate">
              <AttachmentIcon className="h-5 w-5 flex-shrink-0" />
              <div className="truncate">{file?.name}</div>
            </div>
            <div>
              <TrashIcon className="h-4 w-4" onClick={deleteFile} />
            </div>
          </div>
        )}
        {fileUploadProgress > 0 && (
          <div className="flex items-center gap-4">
            <div className="flex-grow">
              <ProgressBar useProgressColours progress={fileUploadProgress} />
            </div>
            <div className="flex-shrink-0">{(fileUploadProgress * 100).toFixed(0)}%</div>
          </div>
        )}
      </div>
    </>
  );
};

export default UploadFile;
